import { Link } from "react-router-dom";
import Productcard from "../../components/productcard/Productcard";
import { i18n } from "../../config/";
import "./Productselect.css";
import { useEffect, useContext, useState } from "react";
import { myContext } from "../../App";
import { send } from "../../remote/send";
import Button from "react-bootstrap/Button";

function Productselect() {
  const { products, setProducts } = useContext(myContext);
  const [someSelected, setSomeSelected] = useState(false);
  useEffect(() => {
    send("get", "/getorder").then((res) => setProducts(res.data.orderlines));
  }, [setProducts]);

  const qtyChangeHandler = (value, orderline) => {
    let newProducts = products;
    products.find((el) => el.orderlineid === orderline).qtyreturn = parseInt(
      value
    );
    newProducts.filter((el) => el.qtyreturn > 0).length > 0
      ? setSomeSelected(true)
      : setSomeSelected(false);

    setProducts(newProducts);
  };

  return (
    <div className="productselect">
      <header className="productselect-header">
        <h3>{i18n.get("chooseProducts")}</h3>
      </header>
      <div className="productselect-content">
        {products.map((prod) => (
          <Productcard
            orderline={prod.orderlineid}
            key={prod.orderlineid}
            header={`${prod.brandname} ${prod.productname} (${prod.brandsku})`}
            leftName={i18n.get("price")}
            leftValue={`${Math.round(prod.price * 100) / 100} ${
              prod.countrycode === "SE"
                ? "SEK"
                : prod.countrycode === "FI"
                ? "EUR"
                : "NOK"
            }`}
            rightName={i18n.get("returnQuantity")}
            dropdownValues={Array.from(Array(prod.qty + 1).keys())}
            defaultValue={prod.qtyreturn}
            dropdownChangehandler={qtyChangeHandler}
          />
        ))}
      </div>
      {someSelected ? (
        <Link className="next-link" to="/selectreason">
          <Button className="continue-btn" variant="success" type="submit">
            {i18n.get("continue")}
          </Button>
        </Link>
      ) : (
        <Button className="select-some-msg" variant="outline-danger">
          {i18n.get("minimumOne")}
        </Button>
      )}
    </div>
  );
}

export default Productselect;
