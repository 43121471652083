import { Link } from "react-router-dom";
import { i18n } from "../../config/";
import "./Labelconfirmation.css";
import Button from "react-bootstrap/Button";

function Labelconfirmation() {
  return (
    <div className="labelconfirmation">
      <h1>{i18n.get("youreWelcome")}</h1>
      <p>{i18n.get("sendConfirm")}</p>

      <Link to="/registered" className="logout">
        <Button variant="outline-dark">{i18n.get("back")}</Button>
      </Link>
      <Link to="/login" className="logout">
        <Button variant="info">{"Log out"}</Button>
      </Link>
    </div>
  );
}

export default Labelconfirmation;
