import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Login from "./views/login/Login";
import Productselect from "./views/productselect/Productselect";
import Reasonselect from "./views/reasonselect/Reasonselect";
import Registered from "./views/registered/Registered";
import Labelconfirmation from "./views/labelconfirmation/Labelconfirmation";
import { useState, createContext, useEffect } from "react";
import "./App.css";
import { CONFIG, i18n } from "./config/";
export const myContext = createContext();
function App() {
  const [products, setProducts] = useState([]);
  const [, setIsLang] = useState(false);

  useEffect(() => {
    const language = navigator.languages[0];
    if (language.includes("no")) {
      i18n.setLang("no");
    } else if (language.includes("sv")) {
      i18n.setLang("se");
    } else if (language.includes("fi")) {
      i18n.setLang("fi");
    } else {
      i18n.setLang("no");
    }
    setIsLang(true);
  }, []);
  return (
    <myContext.Provider
      value={{
        products,
        setProducts,
      }}
    >
      <Router>
        <div className="App">
          <header className="App-header">
            <div class="topInfo">
              <a class="topA" href="https://milrabhelp.zendesk.com/hc/no">
                Returportalen er for øyeblikket nede, benytt returetiketten du
                fikk i pakken din. Har du behov for assistanse, ta kontakt med
                <span class="spanText"> kundeservice her.</span>
              </a>
            </div>
            <img src={CONFIG.logo_path} alt="logo" id="hdr_logo" />
          </header>
          <Switch>
            <Route exact path="/">
              {true ? <Redirect to="/Login" /> : <h1>yo</h1>}
            </Route>
            <Route exact path="/" children={<Login />} />
            <Route exact path="/login" children={<Login />} />
            <Route path="/login/:params" children={<Login />} />
            <Route exact path="/selectproducts" children={<Productselect />} />
            <Route exact path="/selectreason" component={Reasonselect} />
            <Route exact path="/registered" component={Registered} />
            <Route
              exact
              path="/labelconfirmation"
              component={Labelconfirmation}
            />
            <Route path="/">
              <Redirect to="/Login" />
            </Route>
          </Switch>
        </div>
      </Router>
    </myContext.Provider>
  );
}

export default App;
