const i18n = require("./i18n");

const CONFIG = {
  logo_path: "https://img2-327a.kxcdn.com/di1.ashx/70/2156225/0/30",
  backend_base_url: "https://customer-portal-backend.dokku.milrab.com", //,"http://localhost:3001", //
  // onProductSelectCard: {
  //   header: {dataName: ['brandname','name']},
  //   body: [{name:'price', dataName:'cost'}, ]
  // }
};

module.exports = {
  CONFIG,
  i18n,
};
