import { Link } from "react-router-dom";
import { i18n } from "../../config/";
import { useContext, useState } from "react";
import { myContext } from "../../App";
import Productcard from "../../components/productcard/Productcard";
import "./Reasonselect.css";
import { send } from "../../remote/send";
import Button from "react-bootstrap/Button";

function Reasonselect() {
  const { products, setProducts } = useContext(myContext);
  const [allReasons, setAllReasons] = useState(false);

  const reasonChangeHandler = (value, orderline) => {
    let newProducts = products;
    products.find((el) => el.orderlineid === orderline).returnReason = value;
    newProducts.filter((prod) => prod.qtyreturn > 0 && !prod.returnReason)
      .length === 0
      ? setAllReasons(true)
      : setAllReasons(false);
    setProducts(newProducts);
  };

  const commentChangeHandler = (comment, orderline) => {
    let newProducts = products;
    newProducts.find((el) => el.orderlineid === orderline).comment = comment;
    setProducts(newProducts);
  };

  const sendReturnHandler = async () => {
    const reasons = i18n.get("reasons");
    const body = products
      .filter((p) => p.qtyreturn > 0)
      .map((prod) => {
        const { code } = reasons.find((el) => el.reason === prod.returnReason);
        return {
          orderid: prod.orderid,
          orderlineid: prod.orderlineid,
          productid: prod.productid,
          reason: code,
          quantity: prod.qtyreturn,
          registeredat: new Date(),
          sku: prod.brandsku,
          comment: prod.comment || null,
        };
      });
    try {
      await send("post", "/registerReturn", body);
    } catch (e) {}
  };

  return (
    <div className="reasonselect">
      <header className="reasonselect-header">
        <h3>{i18n.get("chooseReason")}</h3>
      </header>
      <div className="reasonselect-content">
        {products
          .filter((prod) => prod.qtyreturn > 0)
          .map((prod) => (
            <Productcard
              orderline={prod.orderlineid}
              key={prod.orderlineid}
              header={`${prod.brandname} ${prod.productname} (${prod.brandsku})`}
              leftName={i18n.get("returnQuantity")}
              leftValue={prod.qtyreturn}
              rightName={i18n.get("chooseReason")}
              dropdownValues={i18n.get("reasons").map((el) => el.reason)}
              dropdownChangehandler={reasonChangeHandler}
              commentChangeHandler={commentChangeHandler}
            />
          ))}
      </div>
      <div className="buttons">
        <Link to="/selectproducts">
          <Button className="btn" variant="outline-dark">
            {i18n.get("back")}
          </Button>
        </Link>
        {allReasons ? (
          <Link to="/registered">
            <Button
              className="btn"
              variant="success"
              onClick={sendReturnHandler}
            >
              {i18n.get("send")}
            </Button>
          </Link>
        ) : (
          <Button className="btn" variant="outline-danger">
            <p className="missing-reason">{i18n.get("missingReason")}</p>
          </Button>
        )}
      </div>
    </div>
  );
}

export default Reasonselect;
