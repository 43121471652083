const axios = require("axios");
const { CONFIG } = require("../config/index");

const getHeaders = () => {
  const obj = {
    headers: {
      Authorization: `${window.localStorage.getItem("token")}`,
    },
  };
  return obj;
};

const send = async (method, path, body) => {
  const url = `${CONFIG.backend_base_url}${path}`;
  let response = {};
  try {
    method.toLowerCase() === "post"
      ? await axios
          .post(url, body, getHeaders())
          .then((res) => (response = res))
          .catch((e) => (response.err = e.response.status))
      : await axios
          .get(url, getHeaders())
          .then((res) => (response = res))
          .catch((e) => (response.err = e.response.status));
    return response;
  } catch (e) {
    return { err: 500 };
  }
};

export { send };
