import { Link, Redirect } from "react-router-dom";
import { i18n } from "../../config/";
import "./Registered.css";
import Button from "react-bootstrap/Button";
import { send } from "../../remote/send";
import { useState } from "react";
import Alert from "react-bootstrap/Alert";

function Registered() {
  const [labelFail, setLabelFail] = useState({
    isFail: false,
  });
  const [redirect, setRedirect] = useState(false);
  const labelHandler = async () => {
    const res = await send("get", "/getlabel");
    if (res.err) {
      setLabelFail({ isFail: true });
    } else {
      setLabelFail({ isFail: false });
      setRedirect(true);
    }
  };
  return (
    <div className="registered">
      {redirect ? <Redirect to="/labelconfirmation" /> : ""}
      <h1>{i18n.get("thanks")}</h1>

      <p>{i18n.get("registered")}</p>
      {/* <p>
        {i18n.get("registered")}{" "}
        <a rel="noreferrer" href={i18n.get("instructionsLink")} target="_blank">
          {i18n.get("instructionsLink")}
        </a>
      </p> */}

      {/* <a
        rel="noreferrer"
        href="https://www.bring.no/retur/?q=10026207299"
        target="_blank"
      > */}
      <Button onClick={labelHandler} variant="outline-dark">
        {i18n.get("needLabel")}
      </Button>
      {/* </a> */}
      <Link to="/login" className="logout">
        <Button variant="info">{i18n.get("logOut")}</Button>
      </Link>
      {labelFail.isFail ? (
        <Alert className="label-alert" variant="danger">
          <p>{i18n.get("errorMsg")}</p>
        </Alert>
      ) : (
        ""
      )}
    </div>
  );
}

export default Registered;
