import { useParams, Redirect } from "react-router-dom";
import "./Login.css";
import { i18n } from "../../config/";
import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import { send } from "../../remote/send";

function Login() {
  const [phone, setPhone] = useState("");
  const [orderNr, setOrderNr] = useState("");
  const [loginFail, setLoginFail] = useState({
    isFail: false,
  });
  const [redirect, setRedirect] = useState(false);
  let { params } = useParams();
  var searchParams = new URLSearchParams(params);
  const submitLogin = async (e) => {
    e.preventDefault();
    login(phone, orderNr);
  };

  const login = async (phone, orderNr, loginMethod) => {
    const res = await send("post", "/login", {
      phone,
      orderNr,
      loginMethod:loginMethod?loginMethod:'manual'
    });
    if (!res.err && res.status === 200) {
      window.localStorage.setItem("token", `JWT ${res.data.token}`);
      window.localStorage.setItem("name", res.data.name);
      setRedirect(true);
    } else {
      if(loginFail.isFail){
        setLoginFail({ isFail: false});
        setTimeout(() => {
          setLoginFail({ isFail: true});
        }, 200);
      }else{
        setLoginFail({ isFail: true});
      }
    }
  };

  useEffect(() => {
    if (searchParams.get("phone") && searchParams.get("order")) {
      const method = searchParams.get("method")
      login(searchParams.get("phone"), searchParams.get("order"), method);
    } else {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="login">
      {redirect ? <Redirect to="/selectproducts" /> : ""}
      <header className="login-header">
        <h3>{i18n.get("login")}</h3>
      </header>
      <div className="login-content">
        <Form onSubmit={(e) => submitLogin(e)}>
          <Form.Group controlId="formBasicPhone">
            <Form.Label>{i18n.get("phone")}</Form.Label>
            <Form.Control
              onChange={(e) => setPhone(e.target.value)}
              type="phone"
              placeholder={i18n.get("phoneExample")}
            />
          </Form.Group>
          <Form.Group controlId="formBasicOrder">
            <Form.Label>{i18n.get("orderNr") + "  (Ref.)"}</Form.Label>
            <Form.Control
              onChange={(e) => setOrderNr(e.target.value)}
              type="number"
              placeholder=""
            />
          </Form.Group>
          <Button variant="success" type="submit">
            {i18n.get("login")}
          </Button>
        </Form>
      </div>
      {loginFail.isFail ? (
        <Alert className="login-alert" variant="danger">
          <p>{i18n.get("errorMsg")}</p>
        </Alert>
      ) : (
        ""
      )}
    </div>
  );
}

export default Login;
