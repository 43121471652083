import { useEffect, useState } from "react";
import { i18n } from "../../config/";

import "./Productcard.css";

function Productcard(props) {
  const [dropdownValue, setdropdownValue] = useState(0);
  const [comment, setComment] = useState("");
  const {
    header,
    leftName,
    leftValue,
    rightName,
    orderline,
    dropdownValues,
    dropdownChangehandler,
    commentChangeHandler,
    defaultValue,
  } = props;

  const handleChange = (value) => {
    setdropdownValue(value);
    dropdownChangehandler(value, orderline);
  };

  const handleCommentChange = (comment) => {
    setComment(comment);
    commentChangeHandler(comment, orderline);
  };

  return (
    <div className="productcard">
      <h5 className="product-name">{header}</h5>
      <div className="product-info">
        <div className="left-info">
          <p>{leftName}</p>
          <p>{leftValue}</p>
        </div>
        <label className="right-info">
          <p>{rightName}</p>
          <select
            className="dropdown"
            value={dropdownValue}
            onChange={(e) => handleChange(e.target.value)}
            defaultValue={defaultValue}
          >
            {dropdownValues.map((el) => {
              return (
                <option key={el.code} value={el.reason}>
                  {el}
                </option>
              );
            })}
          </select>
        </label>
      </div>
      {rightName === i18n.get("chooseReason") ? (
        <div className="comment-section">
          <label className="comment-label" for="comment_input">
            {i18n.get("comment")}
          </label>
          <textarea
            id="comment_input"
            value={comment}
            onChange={(e) => handleCommentChange(e.target.value)}
          ></textarea>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Productcard;
