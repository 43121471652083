const strings = {
  login: {
    no: "Logg inn",
    se: "Logga in",
    fi: "Kirjaudu sisään",
  },
  phone: {
    no: "Telefonnummer:",
    se: "Telefonnummer:",
    fi: "Puhelinnumero:",
  },
  phoneExample: {
    no: "",
    se: "",
    fi: "",
  },
  orderNr: {
    no: "Ordrenummer:",
    se: "Ordernummer:",
    fi: "Tilausnumero",
  },
  price: {
    no: "Pris:",
    se: "Pris:",
    fi: "Hinta:",
  },
  returnQuantity: {
    no: "Antall å returnere:",
    se: "Antal att returnera",
    fi: "Palautettavien tuotteiden määrä",
  },
  currency: {
    no: "NOK",
    se: "SEK",
    fi: "EUR",
  },
  chooseProducts: {
    no: "Velg produkt(er) for retur:",
    se: "Välj produkt(er) för retur:",
    fi: "Valitse palautettavat tuotteet:",
  },
  minimumOne: {
    // add to trello
    no: "Velg minst ett produkt for å gå videre",
    se: "Välj minst en produkt för att gå vidare",
    fi: "Valitse ainakin yksi tuote jatkaaksesi",
  },
  continue: {
    no: "Neste",
    se: "Nästa",
    fi: "Seuraava",
  },
  send: {
    no: "Send inn",
    se: "Sänd in",
    fi: "Lähetä",
  },
  back: {
    no: "Tilbake",
    se: "Tillbaka",
    fi: "Takaisin",
  },
  chooseReason: {
    no: "Årsak til retur",
    se: "Orsak till retur",
    fi: "Palautuksen syy",
  },
  reasons: {
    no: [
      {
        code: 0,
        reason: "",
      },
      {
        code: 1,
        reason: "Ikke fornøyd med design/passform",
      },
      // {
      //   code: 2,
      //   reason: "Størrelsen passet ikke",
      // },
      {
        code: 11,
        reason: "For liten størrelse",
      },
      {
        code: 12,
        reason: "For stor størrelse",
      },
      {
        code: 3,
        reason: "Ikke fornøyd med farge",
      },
      {
        code: 4,
        reason: "Bestilte flere størrelser/farger",
      },
      {
        code: 5,
        reason: "Produktet var ikke lik beskrivelsen",
      },
      {
        code: 6,
        reason: "Forsinket levering",
      },
      {
        code: 7,
        reason: "Varen var skadet ved mottak",
      },
      {
        code: 8,
        reason: "Mottatt feil vare",
      },
      {
        code: 9,
        reason: "Reklamasjon",
      },
      {
        code: 10,
        reason: "Annet",
      },
    ],
    se: [
      {
        code: 0,
        reason: " ",
      },
      {
        code: 1,
        reason: "Inte nöjd med design/passform",
      },
      // {
      //   code: 2,
      //   reason: "Storleken passar inte ",
      // },
      {
        code: 11,
        reason: "För liten storlek",
      },
      {
        code: 12,
        reason: "För stor storlek",
      },
      {
        code: 3,
        reason: "Inte nöjd med färg",
      },
      {
        code: 4,
        reason: "Beställde flera storlekar",
      },
      {
        code: 5,
        reason: "Produkten motsvarade inte beskrivning",
      },
      {
        code: 6,
        reason: "Försenad leverans",
      },
      {
        code: 7,
        reason: "Varan var skadad vid leverans",
      },
      {
        code: 8,
        reason: "Mottagit fel produkt",
      },
      {
        code: 9,
        reason: "Reklamation",
      },
      {
        code: 10,
        reason: "Annat",
      },
    ],
    fi: [
      {
        code: 0,
        reason: "",
      },
      {
        code: 1,
        reason: "Design/istuvuus ei ole mieleen",
      },
      // {
      //   code: 2,
      //   reason: "Koko ei ole sopiva",
      // },
      {
        code: 11,
        reason: "Liian pieni koko",
      },
      {
        code: 12,
        reason: "Liian suuri koko",
      },
      {
        code: 3,
        reason: "En tykkää väristä",
      },
      {
        code: 4,
        reason: "Tilasin useita eri kokoja",
      },
      {
        code: 5,
        reason: "Tuote ei vastaa kuvausta",
      },
      {
        code: 6,
        reason: "Viivästynyt toimitus",
      },
      {
        code: 7,
        reason: "Tuote oli vaurioitunut toimituksen aikana",
      },
      {
        code: 8,
        reason: "Väärä tuote vastaanotettu",
      },
      {
        code: 9,
        reason: "Valitus",
      },
      {
        code: 10,
        reason: "Muu",
      },
    ],
  },
  missingReason: {
    // add to trello
    no: "Vennligst velg årsak for å gå videre",
    se: "Vänligen välj orsak för att gå vidare",
    fi: "Valitse aihe jatkaaksesi",
  },
  thanks: {
    no: "Takk!",
    se: "Tack!",
    fi: "Kiitos!",
  },
  // registered: {
  //   no:
  //     "Din retur er nå registrert. Vennligst følg instruksjonene du finner ved å følge linken:",
  //   se: "Din retur är nu registrerad. Vänligen följ instruktioner nedan", // LEGG TIL SISTE SETNING OVER
  //   fi: "Palautuksesi on nyt rekisteröity. Noudata alla olevia ohjeita", // LEGG TIL SISTE SETNING OVER
  // },
  registered: {
    no: "Din retur er nå registrert.",
    se: "Din retur är nu registrerad.",
    fi: "Palautuksesi on nyt rekisteröity.",
  },
  instructionsLink: {
    no: "http://script.milrab.no/milrab/retur/Retur-ny2.pdf",
    se: "https://asset1-327a.kxcdn.com/w624095/static.ashx/retur/lang/v2/ReturSWE.pdf",
    fi: "http://asset1-327a.kxcdn.com/w624095/static.ashx/retur/lang/v2/ReturFIN.pdf",
  },
  needLabel: {
    no: "Jeg trenger en fraktetikett",
    se: "Jag behöver en fraktsedel",
    fi: "Tarvitsen rahtikirjan",
  },
  logOut: {
    no: "Logg ut",
    se: "Logga ut",
    fi: "Kirjaudu ulos",
  },
  youreWelcome: {
    no: "Vær så god!",
    se: "Varsågod!",
    fi: "Ole hyvä!",
  },
  sendConfirm: {
    no: "Din fraktetikett har nå blitt sendt til epostadressen du brukte da du la inn din bestilling",
    se: "Din fraktsedel har nu blivit sänd till den mailadressen du använde vid beställningen.",
    fi: "Rahtikirjasi on nyt lähetetty sähköpostiosoitteeseen, jota käytit tilauksen yhteydessä.",
  },
  errorMsg: {
    no: "Noe gikk galt. Prøv igjen eller kontakt vår kundeservice på support@milrab.no",
    se: "Något gick fel. Pröva igen eller kontakta vår kundservice på support@milrab.se",
    fi: "Jotain meni pieleen. Yritä uudelleen tai ota yhteyttä asiakaspalveluumme osoitteessa support@milrab.fi",
  },
  comment: {
    no: "Kommentar:",
    se: "Kommentar:",
    fi: "Kommentti:",
  },
};

let lang = "no";

const setLang = (l) => (lang = l);

const get = (key) => strings[key][lang];

module.exports = {
  setLang,
  get,
};
